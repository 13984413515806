import React, { useState, useEffect } from "react"
import { Row, Col, Button, Spin, notification } from 'antd';
import { get, sumBy, isEmpty } from 'lodash'
import moment from 'moment'
import { t } from '../../../helpers/translation'
import CloseSvg from '-!react-svg-loader!/public/images/x.svg';
import OpenSvg from '-!react-svg-loader!/public/images/o.svg';
import PreSvg from '-!react-svg-loader!/public/images/pre.svg';
import NextSvg from '-!react-svg-loader!/public/images/next.svg';
import { getStaffSchedules } from '../../../services/staffs'
import { getStoreAcceptedNumber, getBusinessHours } from '../../../services/stores'
import { addSchedule } from '../../../helpers/bookings'
import { getTimeRanges } from "../../../helpers/datetime"
import { TIME_FORMAT, DATE_FORMAT } from '../../../utils/datetime'
import './styles.scss'

const DATE_KEY_FORMAT = 'YYYYMMDD';

export const Schedule = ({ staffCapacity, storeId, stylist, onChangeSchedule, selectedDate, timeVisitFrom, selectedMenus, coupons, staffIds, isUpdate, reservationId }) => {
  const [totalTreatmentTime, setTotalTreatmentTime] = React.useState(null);
  const [currentDate, setCurrentDate] = useState(moment(selectedDate) || moment.utc());
  const [weekDays, setWeekDays] = useState([]);
  const [schedules, setSchedules] = useState({});
  const [acceptedNumbers, setAcceptedNumbers] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectDate, setSelectDate] = useState(moment(selectedDate).toDate() || null);
  const [selectTime, setSelectTime] = useState(timeVisitFrom || null);
  const [minMaxBusinessHour, setMinMaxBusinessHour] = useState({});

  useEffect(() => {
    const startOfWeek = moment(currentDate).startOf('isoWeek');
    const endOfWeek = moment(currentDate).endOf('isoWeek');
    let days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }

    setWeekDays(days);
    
  }, [currentDate.format()])

  React.useEffect(() => {
    const menuCoupons = coupons?.filter((coupon) => coupon?.defineCategory);
    const totalTime = sumBy(selectedMenus, 'requiredTime') + sumBy(menuCoupons, 'treatmentDuration');
    setTotalTreatmentTime(totalTime);
    if(!totalTime) {
      setAcceptedNumbers({});
      setSchedules({});
    }
  }, [selectedMenus, coupons])

  const onGetStaffSchedule = async (date) => {
    setLoading(true);

    const res = await getStaffSchedules({
      date: date.format(DATE_KEY_FORMAT),
      staff_id: stylist?.id,
      store_id: storeId,
      required_time: totalTreatmentTime,
      excluded_reservation_ids: [reservationId]
    });
    const resAcceptedNumber = await getStoreAcceptedNumber(storeId, {
      date: date.format(DATE_KEY_FORMAT),
      staff_id: stylist?.id,
      required_time: totalTreatmentTime
    });

    if (resAcceptedNumber) { setAcceptedNumbers(resAcceptedNumber.data) }
    if (res) { setSchedules(res.data) }

    setLoading(false);
  }

  const onGetBusinessHour = async (date, filterBy) => {
    const params = { date: date, filter_by: filterBy }
    getBusinessHours(storeId, params).then(function (response) {
      if (isEmpty(response.data)) {
        setMinMaxBusinessHour({
          min: 9,
          max: 23
        });
        return;
      }

      const closeTimeInSeconds = Object.values(response.data).map((item) => {
        const seconds = moment(item.closeTime, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
        return seconds;
      })
      const openTimeInSeconds = Object.values(response.data).map((item) => {
        const seconds = moment(item.openTime, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
        return seconds;
      })
      setMinMaxBusinessHour({
        min: Math.min(...openTimeInSeconds) / 3600,
        max: Math.max(...closeTimeInSeconds) / 3600
      });
    });
  };

  const onNextWeek = () => {
    const nextWeek = currentDate.add(1, 'weeks')
    if(totalTreatmentTime) { onGetStaffSchedule(nextWeek); }
    onGetBusinessHour(currentDate.format(), 'week');
    setCurrentDate(nextWeek);
  }

  const onPreviousWeek = () => {
    const previousWeek = currentDate.subtract(1, 'weeks')
    if(totalTreatmentTime) { onGetStaffSchedule(previousWeek); }
    onGetBusinessHour(currentDate.format(), 'week');
    setCurrentDate(previousWeek);
  }

  useEffect(() => {
    if(totalTreatmentTime) { onGetStaffSchedule(currentDate); }
    onGetBusinessHour(currentDate.format(), 'week');
  }, [totalTreatmentTime])

  const onGoToStore = () => {
    window.location.href = `/reservation_users/stores/${storeId}`
  }

  const onSelectAnotherStylist = () => {
    if (staffCapacity === 0 || (staffIds.includes(stylist.id) && staffIds.length === 1)) {
      notification.error({
        message: t('booking.noOtherStylist')
      });
    } else {
      window.location.href = `/reservation_users/stores/${storeId}?active_tab=2`
    }
  }

  const onSelectDateVisit = (day, time) => {
    setSelectDate(day);
    setSelectTime(time);
    addSchedule({ day: moment(day).format(DATE_KEY_FORMAT), time: time })
    onChangeSchedule();
  }

  const DateFiler = ({ date }) => {
    return (
      <div className="grid grid-cols-3 gap-4 justify-items-center my-4 px-4">
        <Button className="rounded btn btn-outline-secondary text-center" onClick={onPreviousWeek} disabled={moment(currentDate).startOf('isoWeek').isSameOrBefore(moment())}>
          <div className="flex flex-row items-center justify-between">
            <PreSvg />
            <span>{t('booking.previousWeek')}</span>
          </div>
        </Button>
        <div className="self-center text-center">
          { date && date.length > 0 ? moment(date[0]).format(DATE_FORMAT) : moment().format(DATE_FORMAT)}
        </div>
        <Button className="rounded btn btn-outline-secondary text-center" onClick={onNextWeek} disabled={moment(currentDate).endOf('isoWeek').isSameOrAfter(moment().add(90, 'd'))}>
          <div className="flex flex-row items-center justify-between">
            <span>{t('booking.nextWeek')}</span>
            <NextSvg />
          </div>
        </Button>
      </div>
    )
  }

  const AvailableBox = ({day, time}) => {
    const [isSelected, setIsSelected] = useState(false)
    const timeItem = moment(time, TIME_FORMAT);
    const dayItem = moment(day).format(DATE_KEY_FORMAT)
    const dayCheck = moment(dayItem).day()
    const [schedule, setSchedule] = useState(schedules[dayItem]);
    const [acceptedNumber, setAcceptedNumber] = useState(acceptedNumbers[dayItem]);
    const [isBusy, setIsBusy] = useState(true);
    const [isHoliday, setIsHoliday] = useState(false);

    useEffect(() => {
      if (moment(`${dayItem} ${time}`, `${DATE_KEY_FORMAT} ${TIME_FORMAT}`).isBefore(moment())) {
        return
      }
      
      if (acceptedNumber) {
        const acceptNumberData = acceptedNumber[time]
        const remainAcceptNumber = acceptNumberData?.remainingAcceptedNumber;
        if (remainAcceptNumber <= 0) { return }
      }

      if (schedule) {
        setIsHoliday(schedule.isHoliday);
        const ranges = schedule.timeRange
        const timeCompare = time === '23:00' ? '[]' : '[)'
        let disableWeekends = false
        let tmpBusy = true;
        if((dayCheck == 6 || dayCheck == 0) && coupons[0]?.weekdayOnly){
          disableWeekends = true
        }
        
        ranges.forEach(element => {
          if (element.startTime && element.endTime && tmpBusy) {
            const beforeTime = moment(element.startTime, TIME_FORMAT);
            const afterTime = moment(element.endTime, TIME_FORMAT);
            const isBetween = timeItem.isBetween(beforeTime, afterTime, 'minutes', timeCompare);
            tmpBusy = !isBetween
          }
        });

        setIsBusy(tmpBusy || schedule.isHoliday || disableWeekends || (schedule?.acceptedNumber && schedule?.acceptedNumber[time]?.acceptedNumber <= 0))
      }
    }, [schedule, acceptedNumber])

    useEffect(() => {
      if (selectDate.getTime() == day.getTime() && selectTime == time) {
        setIsSelected(true)
      }
    }, [selectDate, selectTime])

    return (
      <Col span={3} className="text-center font-bold h-10" onClick={() => !isBusy && onSelectDateVisit(day, time)}>
        <div className={`h-full flex justify-center items-center ${(isSelected) ? 'active' : ''}`}>
          {(isBusy) 
            ? <CloseSvg />
            : <OpenSvg />
          }
        </div>
      </Col>
    )
  }

  return (
    <div className="py-2 booking__schedule">
      <div className="bg-white py-2">
        <DateFiler date={weekDays} />
        <Spin spinning={loading}>
          <Row gutter={[0, 0]} className="divide-y divide-x border-r border-b bg-white">
            <Col span={3} className="text-center font-bold border-t border-l" style={{height: "60px"}} />
            {weekDays.map((item, idx) => {
              const weekDay = moment(item);

              return (
                <Col key={idx} span={3} className="text-center font-bold" style={{height: "60px"}}>
                  <div>{weekDay.month()+1}月</div>
                  <div>{weekDay.date()}日</div>
                  <div className="text-2xs">({t(`common.enums.dayOfWeeks.${weekDay.day()}`)})</div>
                </Col>
              )
            })}
            {getTimeRanges(minMaxBusinessHour.min || 9, minMaxBusinessHour.max || 23).map((time, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Col span={3} className="text-center font-bold text-2xs h-10 bg-gray-50">
                    <div className="h-full flex justify-center items-center">
                      {time}
                    </div>
                  </Col>
                  {weekDays.map((day, idx) => {
                    return (
                      <AvailableBox time={time} day={day} key={idx} />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Row>
        </Spin>
        <DateFiler />
      </div>
      <div className="mx-4 my-6">
        <div className="flex flex-row items-center justify-center bg-gray-50 rounded py-2 btn btn-small btn-outline-secondary mb-2">
          <div className="mr-2" style={{ width: '14px', height: '14px' }}><img src='/images/phone.svg' /></div>
          <a className="store__phone" href={`tel:${get(stylist, 'store.phoneNumber')}`}>{get(stylist, 'store.phoneNumber')}</a>
        </div>
        { !isUpdate &&
          <div className="flex flex-row items-center justify-center space-x-2">
            <div className="w-2/4">
              <Button className="rounded btn btn-small btn-outline-secondary text-center" onClick={onGoToStore}>
                <span className="font-bold">{t('booking.salonInfoTitle')}</span>
              </Button>
            </div>
            <div className="w-2/4">
              <Button className="rounded btn btn-small btn-outline-secondary text-center font-bold" onClick={onSelectAnotherStylist}>
                <span className="font-bold">{t('booking.selectAnotherStylistTitle')}</span>
              </Button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
